'use strict';
var $ = window.$;

let mediaXxs = 320;
let mediaXs = 480;
let mediaSm = 768;
let mediaMd = 1024;
let mediaLg = 1200;
let mediaXl = 1440;
let mediaXxl = 1600;
let mediaXxxl = 1920;

$(document).ready(function () {

  $.fancybox.defaults.touch = false;
  $.fancybox.defaults.toolbar = false;
  $.fancybox.defaults.infobar = false;
  $.fancybox.defaults.arrows = false;
  $.fancybox.defaults.autoFocus = false;
  $.fancybox.defaults.smallBtn = false;

  $('[data-drop-next]').on('click', function(){
    $(this).toggleClass('is-open')
    $(this).siblings().slideToggle(200);
  });

  // Открытие мобильного меню
  $('[js-open-menu]').on('click', function() {
    $('body').toggleClass('menu-open');
    $(this).toggleClass('is-open');
    $('#mobile-menu').toggleClass('is-open');
  })

  // animations

  $('[js-animate]').each(function(){
		let $block = $(this);
		$(window).on('scroll', function(){
			let scrollTop = $(window).scrollTop();
			let windowHeight = $(window).height();
			if ($block.offset().top < (scrollTop + windowHeight)) {
				$($block).each(function(){
					let name = $(this).attr('js-animate');
					$(this).addClass('animate__animated animate__' + name);
				})
			}
		}).trigger('scroll');
	})

  $('[gallery-slider]').each(function() {
		let $block = $(this);
		let $init = $block.find('[class*="__list"]');
		let $prev = $block.find('[class*="_prev"]');
		let $next = $block.find('[class*="_next"]');

    $init.slick({
      infinite: true,
      sliderToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      arrows: true,
      dots: false,
      prevArrow: $prev,
      nextArrow: $next,
    });
	})
  $('[review-slider]').each(function() {
		let $block = $(this);
		let $init = $block.find('[class*="__list"]');
    let $dots = $block.find('[class*="-dots"]')

    $init.slick({
      infinite: true,
      sliderToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      arrows: false,
      dots: true,
      appendDots: $dots,
      responsive: [
        {
          breakpoint: 480 - 1,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024 - 3,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ],
    });
	})

});